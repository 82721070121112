@import "../../../../styles/theme.scss";

.container {
  display: flex;
  gap: 32px;
  background-color: $pale-mint;
  align-items: center;
  padding: 22px 32px;
  margin-top: 24px;
}

.brokenLink {
  height: 60px;
  width: 60px;
  color: $disconnect-error;
}

.uhOhTitle {
  margin-bottom: 8px;
}

.description {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.4;
}
