@import "../../../../styles/theme.scss";

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 24px 0;
  box-shadow: 0 0 30px 0 rgba(9, 28, 55, 0.1);
  margin: 0 30px;
}

.brokerageLogo {
  height: 70%;
  width: 70%;
  padding: 0 10px;
  object-fit: contain;
}

.childContainer {
  @media (max-width: $small) {
    display: flex;
    gap: 8px;
    padding: 0 20px;
  }
}

.brokerageName {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 0;
  margin-top: 24px;
}

