.container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}

.logo {
  max-height: 30px;
  max-width: 100px;
}
