.certificateImage {
  max-width: 100%;
}

.downloadButtonContainer {
  display: flex;
  justify-content: center;
}

.downloadButton {
  margin-top: 20px;
}
