@import "../../../../styles/theme.scss";

.DropDownSelect {
  width: 100%;
  padding: 11.25px 50px 11.25px 24px;
  border: solid 1px $light-grey;
  transition: border-color 0.3s;
  font-size: 18px;
  line-height: 1.75;
  outline: none;
  color: grey;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%2385bb65' viewBox='0 0 16 16'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) center;
  background-color: white;
  overflow: hidden !important;
  text-overflow: ellipsis;

  &:autofill,
  &:-webkit-autofill,
  &:-moz-autofill,
  &:-moz-autofill-preview {
    background-color: #dceefe !important;
  }

  option {
    color: grey;
  }

  &:hover {
    border-color: $main;
  }

  &_Slim {
    font-size: 16px;
    line-height: 1.2;
    padding: 11px 21px;
  }

  &_Inverse {
    color: white;
  }

  &_Error,
  &_Error:hover {
    border-color: $error;
  }

  &_Error {
    padding-right: 50px;
    background-position: calc(100% - 48px) center;
  }
}

.inline {
  display: flex;
  gap: 40px;
  margin-bottom: 10px;
}
