@import "../../styles/theme.scss";

.BrokerageAccount {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    font-size: 16px;
    line-height: 1.5;
    color: $navy;

    @media screen and (max-width: $medium) {
      grid-template-columns: 1fr;
    }
  }

  &__label {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 1.9px;
    color: $grey;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action {
    font-size: 16px;
    line-height: 1.2;
    padding: 0;
    margin-right: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__actionIcon {
    margin-right: 8px;
  }
}
