.ConfirmationModal {
  padding: 32px;

  &__title {
    text-align: center;
  }

  &__description {
    text-align: center;
    margin-bottom: 32px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;

    a:first-child {
      margin-right: 32px;
    }
  }
}
