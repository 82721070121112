@import "../../../../styles/theme.scss";

.securitySection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;
  gap: 32px;

  @media (max-width: $medium) {
    margin-top: 16px;
  }

  @media (max-width: $small) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
}

.securityMessage {
  display: flex;
  gap: 14px;
}

.securityTitle {
  font-size: 16px;
  margin-bottom: 6px;
}

.securityDetail {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 1.75;
}

.securityIcon {
  min-height: 32px;
  min-width: 32px;
}

