@import "../../../styles/theme.scss";

.container {
  padding: 0 120px;
  display: flex;
  gap: 120px;
  align-items: center;

  @media (max-width: $large) {
    gap: 64px;
  }

  @media (max-width: $medium) {
    // padding: 40px 60px;
    flex-direction: column;
    padding: 40px;
    gap: 0;
  }

  @media (max-width: $small) {
    padding: 0;
    // min-height: 100%;
    max-height: 100%;
  }
}

.modal {
  position: relative;
}

.paddedModal {
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: $small) {
    padding-top: 20px;
    padding-bottom: 0px;
    width: 100%;
  }
}

.infoContainer {
  width: 45%;

  @media (max-width: $medium) {
    width: 100%;
  }
}

.brokerageContainer {
  position: relative;
  max-width: 420px;
  max-height: 740px;

  @media (max-width: $medium) {
    max-width: 100%;
    max-height: unset;
  }

  &:before {
    content: "";
    background-image: linear-gradient(
      to bottom,
      white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 112px;
    z-index: 3;
  }

  &:after {
    content: "";
    background-image: linear-gradient(
      to top,
      white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 112px;
    z-index: 3;
  }
}

.brokerages {
  padding: 80px 0 100px 0;
  overflow-y: scroll;
  max-height: inherit;

  @media (max-width: $small) {
    margin-bottom: 100px;
  }
}

.securitySection {
  border-top: solid 1px rgba(9, 28, 55, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  background-color: white;
  z-index: 4;
  padding-bottom: 20px;

  @media (max-width: $medium) {
    width: 100%;
  }
}
