@import "../../../../styles/theme.scss";

.container {
  display: flex;
  margin-top: 120px;

  @media (max-width: $small) {
    flex-direction: column;
  }
}

.selectedCard {
  position: relative;
}

.overlayColor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(9, 28, 55, 0.75);
  z-index: 1;
}

.fullHeightCard {
  height: 100%;
  width: 100%;
}

.currentSelectionOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5.8px 13px 7.2px;
  border-radius: 72.4px;
  text-align: center;
  z-index: 2;
  background-color: white;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.card {
  width: calc(50% - 16px);

  @media (max-width: $large) {
    width: 100%;
  }
}

.perkCard {
  flex-direction: column !important;
  height: 100%;
}

.perkCardImage {
  max-height: 232px;
  height: 50%;
}

.perksContainer {
  margin-left: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;

  @media (max-width: $large) {
    flex-wrap: wrap;
  }

  @media (max-width: $small) {
    margin-left: 0;
    margin-top: 32px;
    width: 100%;
  }
}
