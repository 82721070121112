@import "../../../../styles/theme.scss";

.detailColumnWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 16px;
}

.detailTitle {
  font-size: 11px;
  line-height: 12.1px;
  letter-spacing: 0.19em;
  text-transform: uppercase;
  color: $grey;
  margin-bottom: 8px;
}

.detailValue {
  font-size: 18px;
  line-height: 19.8px;
  font-weight: 400;
  color: $secondary;
  margin: 0;

  &__right {
    text-align: right;
  }
}

.description {
  margin-bottom: 16px;
}
