@import "../../styles/theme.scss";

.LoadingCircle {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s linear infinite;
  margin-top: 5px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
