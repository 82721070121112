@import "../../../../styles/theme.scss";

.container {
  position: sticky;
  min-width: 380px;
  height: fit-content;
  top: 80px;

  @media (max-width: $medium) {
    max-width: calc(50% - 30px);
    min-width: unset;
  }

  @media (max-width: $small) {
    position: unset;
    max-width: 100%;
  }
}

.splitEarly {
  min-width: 300px;
  width: 300px;

  @media (max-width: $small) {
    min-width: unset;
    width: 100%;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 44px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.44px;
}

.description {
  font-size: 18px;
  line-height: 1.75;
  margin-bottom: 40px;
}

.requirementContainer {
  background-color: $pale-mint;
  padding: 32px;
  width: 322px;

  @media (max-width: $small) {
    width: 100%;
  }
}

.detailContainer {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.detailTitle {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 2.09px;
}

.detail {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 0;
}

.goToBrandButton {
  margin-bottom: 32px;
}
