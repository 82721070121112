@import "../../../../../styles/theme.scss";

.container {
  width: 100%;
}

.perkTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.codeContainer {
  padding: 16px;
  background-color: $pale-mint;
}

.codeText {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.9px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.couponCode {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

.copyContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 16px;
}

.details {
  margin-top: 16px;

  p {
    font-size: 14px;
  }
}

.copyIcon {
  cursor: pointer;
}
