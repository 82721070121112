@import "../../../../styles/theme.scss";

.brokerageSection {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.brokerageContainer {
  width: 100%;
  height: 180px;

  @media (max-width: $small) {
    width: 100%;
  }

  &_plaid {
    background-color: $navy;
  }
}

.brokerageLogo {
  max-width: 60%;
  max-height: 60%;
}

.plaidLogo {
  max-width: 40% !important;
  max-height: 40% !important;
  margin-bottom: 16px;
  filter: invert(1);

  @media (max-width: $small) {
    margin-bottom: 8px;
  }
}

.plaidContainer {
  padding: 16px 0;
  text-align: center;
}

.plaidText {
  margin-bottom: 0px;
  color: white;
  font-size: 14px;
}
