@import "../../../styles/theme.scss";

.container {
  display: flex;
  gap: 80px;
  margin-top: 20px;
  padding: 100px;

  @media (max-width: $medium) {
    margin-top: 56px;
    gap: 32px;
    padding: unset;
    flex-direction: column-reverse;
  }
}

.side {
  width: calc(50% - 40px);

  @media (max-width: $medium) {
    width: 100%;
  }
}

.inputWrapper {
  label {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.75;
    margin-bottom: 12px;
  }
  margin-bottom: 32px;
}

.verificationTimePeriod {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.1;
  letter-spacing: -0.14px;
}

.updateButton {
  margin-top: 48px;
}

.closeButton {
  margin-top: 20px;

  @media (max-width: $small) {
    margin-bottom: 40px;
  }
}

.fileInputArea {
  border: dashed 2px #8cce65;
  background-color: $pale-mint;
  margin-bottom: 48px;
  padding: 49px 0;
}

.dragAndDrop {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $medium) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: $small) {
    justify-content: left;
  }
}

.fileError {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  color: red;
  @media (max-width: $medium) {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  @media (max-width: $small) {
    text-align: left;
  }
}

.arrow {
  margin-left: 4px;
}

.fileType {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;

  opacity: 0.65;
  font-size: 16px;
  line-height: 1.2;
  @media (max-width: $medium) {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  @media (max-width: $small) {
    text-align: left;
  }
}

.submitButton {
  @media (max-width: $medium) {
    margin-bottom: 40px;
  }
}

.imageContainer {
  direction: rtl;
  height: 100%;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 50%;
  max-height: 50%;
}

.closeWindow {
  margin-top: 20px;
  margin-bottom: 40px;
}
