@import "../../../../styles/theme.scss";

.limitedEdition {
  background-color: $pale-mint;
  border-radius: 16px;
  padding: 4px 18px 6px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  color: $navy;
}

.star {
  height: 12px;
  margin-right: 8px;
}
