.radio {
  margin: 16px 0;
  display: flex;
  gap: 16px;
  font-size: 18px;
}

.option {
  margin-right: 4px;
}
