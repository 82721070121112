.Checkbox {
  width: 100%;
  font-size: 18px;
  color: grey;
  line-height: 1.75;
  outline: none;

  input {
    margin-right: 8px;
  }

  &__label {
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-transform: none !important;
    cursor: pointer;
  }

  &_Inline {
    display: flex;
    flex-direction: row;
  }

  &_Inverse {
    color: white;
  }
}
