@import "styles/theme.scss";

.PerkMegaModal {
  &__modalWrapper {
    padding: 120px 120px;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    overflow: auto;

    @media (max-width: $large) {
      padding: 70px 70px;
    }

    @media (max-width: $small) {
      margin: 0;
      padding: 50px;
    }

    @media (max-width: $xsmall) {
      margin: 0;
      padding: 50px;
    }
  }

  &__row {
    @media (max-width: $medium) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__modalClass {
    @media (max-width: $small) {
      top: 0 !important;
    }
  }

  &__topModal {
    overflow: auto;

    @media (max-width: $small) {
      padding: 0 !important;
      margin: 0 !important;
      width: 100vw !important;
      height: 100vh !important;
      max-height: 100vh !important;
    }
  }

  &__leftColumnWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__rightColumnWrapper {
    margin-left: 80px;

    @media (max-width: $medium) {
      margin-left: 0;
    }
  }

  &__perkImage {
    width: 100%;
  }

  &__disclaimer {
    font-size: 18px;
    line-height: 19.8px;
    font-weight: 400;
    color: $error;
    margin-top: 24px;
  }

  &__brandLogo {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 12px;

    @media (max-width: $medium) {
      margin-top: 40px;
    }
  }

  &__buttonWrapper {
    margin-top: 32px;
    justify-self: flex-end;
  }

  &__claimButton {
    margin-right: 24px;
  }

  &__bundleTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.75;
  }

  &__qualifyingBrands {
    margin-bottom: 32px;
  }

  &__qualifyingBrandsTitle {
    font-size: 20px;
  }

  &__qualifyingBrandsText {
    margin-bottom: 0;
  }

  &__claimAndShare {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 24px;

    @media (max-width: $small) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;
    }
  }

  &__logoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__limitedEdition {
    background-color: $pale-mint;
    border-radius: 16px;
    padding: 4px 18px 6px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    display: flex;
    align-items: center;
    color: $navy;
  }

  &__star {
    height: 12px;
    margin-right: 8px;
  }

  &__brandButton {
    margin-top: 24px;
  }

  &__claimAttempt {
    background-color: $pale-mint;
    padding: 32px;
    word-wrap: break-word;
  }

  &__checkboxContainer {
    padding: 16px;
    background-color: #f0f7f5;

    &__title {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #091c37;
      margin-left: 5px;
    }

    &__optionContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      input[type="checkbox"] {
        appearance: none;
        background: white;
        width: 30px;
        height: 20px;
        cursor: pointer;
        box-sizing: border-box; // Ensures that padding and border are included in the element's total width and height.
        border: solid 1px rgba(87, 90, 96, 0.5);
        padding: 0; // Removes the padding.

        @media (max-width: $xsmall) {
          width: 60px;
        }

        &:checked {
          background: $green-highlight;
        }

        &:checked:after {
          content: "\2713";
          display: block;
          text-align: center;
          color: #ffffff;
          line-height: 20px; // Centers the check mark vertically.
          font-size: 18px;
        }
      }

      &__agreement {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #575a60;
        margin-left: 14px;
      }
    }
  }
}
