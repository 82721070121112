@import "../../../../styles/theme.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 64px;
  box-shadow: 0 0 30px 0 rgba(9, 28, 55, 0.1);

  @media (max-width: $large) {
    padding: 40px;
  }

  @media (max-width: $medium) {
    padding: 32px;
    flex-direction: column;
  }

  @media (max-width: $small) {
    padding: 32px;
    margin: 40px;
    gap: 32px;
  }
}

.actionContainer {
  text-align: right;
}

.buttonContainer {
  display: flex;
  gap: 32px;
  justify-content: right;
  margin-bottom: 16px;

  @media (max-width: $small) {
    flex-direction: column-reverse;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
}

.noAccount {
  font-size: 14px;
  line-height: 1.5;
  color: $navy;
  margin-bottom: 0;
}

.brokerLogo {
  max-width: 214px;
  max-height: 100px;
  min-height: 50px;

  @media (max-width: $small) {
    max-width: 168px;
    max-height: 60px;
  }
}
