@import "../../styles/theme.scss";

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BrokerageLogin {
  &__row {
    display: flex;
    border-bottom: 1px solid $faded-navy;
    cursor: pointer;

    &__broker {
      padding: 16px 24px;
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: normal;
      color: $navy;

      &__accounts {
        font-size: 12px;
        font-weight: 300;
        line-height: 1.1;
        letter-spacing: normal;
        margin-top: 8px;

        @media screen and (min-width: $medium) {
          display: none;
        }
      }

      &__disconnected, &__connected, &__needsReverification, &__verified{
        font-size: 12px;
        font-weight: 300;
        line-height: 1.1;
        letter-spacing: normal;
        margin-left: 9px;

        &__disconnectedIcon, &__reverificationIcon {
          color: $disconnect-error;
          padding-right: .5rem;
        }

        &__connectedIcon, &__verifiedIcon {
          color: $tiicker-green;
          padding-right: .5rem;
        }

        a {
          color: $navy;
          text-decoration: underline;
        }

        @media screen and (max-width: $medium) {
          margin-left: 0;
          margin-top: 8px;
          display: block;
        }
      }
    }

    &__accounts,
    &__value {
      padding: 16px 24px;
      text-align: right;
      width: 150px;
    }

    @media screen and (max-width: $medium) {
      &__accounts {
        display: none;
      }

      &__value {
        padding-left: 0;
        padding-right: 0;
        width: 80px;
      }

      &__actions {
        width: 40px;
      }
    }

    &__actions {
      padding: 16px 24px;
      text-align: right;
      width: 80px;
    }

    &__action {
      cursor: pointer;
      color: $tiicker-green;
    }
  }

  &__rowDetails {
    background-color: $pale-mint;
    overflow: hidden;
    transition: max-height 0.8s ease-in-out;

    &__closed {
      max-height: 0;
    }

    &__innerContainer {
      box-shadow: inset 0 -1px 3px $faded-navy;
      padding: 32px 24px 30px 24px;
    }

    &__account {
      padding: 32px 0;

      &:first-child {
        padding-top: 0;
      }

      &:not(:first-child) {
        border-top: 1px solid $faded-navy;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__action {
      color: $navy;
      font-size: 16px;
      line-height: 1.2;
      padding: 0;
      margin-right: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__actionIcon {
      margin-right: 8px;

      &__spinning {
        margin-right: 8px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}
