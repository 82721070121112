@import "../../styles/theme.scss";

.Brokerages {
  margin-bottom: 56px;

  &__heading {
    font-size: 32px;
    margin-bottom: 14px;
  }

  &__content {
    box-shadow: 0 0 30px 0 rgba(9, 28, 55, 0.1);

    &__heading {
      display: flex;
      font-size: 11px;
      line-height: 1.1;
      letter-spacing: normal;
      border-bottom: 1px solid $faded-navy;

      &__broker {
        padding: 12px 24px;
        flex: 1;
      }

      &__accounts,
      &__value {
        padding: 12px 24px;
        text-align: right;
        width: 150px;
      }

      &__actions {
        padding: 12px 24px;
        text-align: right;
        width: 80px;
      }

      @media screen and (max-width: $medium) {
        &__accounts {
          display: none;
        }

        &__value {
          padding-left: 0;
          padding-right: 0;
          width: 80px;
        }

        &__actions {
          width: 80px;
        }
      }
    }

    &__addBrokerage {
      padding: 20px 0;
      color: $navy;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__label {
        margin-right: 8px;
      }
    }
  }
  &__disclaimer {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
