@import "../../../../styles/theme.scss";

.container {
  background-color: $pale-mint;
  padding: 24px;
  width: 100%;
}

.connectAccounts {
  line-height: 1.4;
  font-size: 16px;
  margin-bottom: 8px;
}

.disclaimer {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4;
}
