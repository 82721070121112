// @import "../../styles/typography.scss";
@import "../../styles/theme.scss";

.SocialShare {
  &__group {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
      font-size: 16px;
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
  &__group__center{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
      margin: 0;
      font-size: 16px;
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.icon {
  &:hover {
    circle {
      fill: $tiicker-green !important;
    }
  }
}
