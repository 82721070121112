@import "../../../../styles/theme.scss";

.subPerkTitle {
  font-size: 16px;
  margin: 8px 0;
  cursor: pointer;
  margin-right: 24px;
}

.subPerkPanel {
  margin-bottom: 20px;
  border: 1px solid $tiicker-green;
  position: relative;
  padding: 14px 20px;

  &_open {
    &:before {
      opacity: 1;
      z-index: 4;
    }
    border: solid 1px transparent;
    box-shadow: 0 0 30px 0 rgba(9, 28, 55, 0.13);

    transition: all 0.7s ease;

    @media (max-width: $small) {
      border: none;
      border-top: solid 1px transparent;
      & + .HomeFaq__panel {
        border-top: none;
      }
    }
  }

  &_close {
    transition: all 0.7s ease;
  }
}

.toggle {
  opacity: 0.7;
  transition: opacity 0.3s;

  &:before,
  &:after {
    position: absolute;
    right: 30px;
    top: 22px;
    content: "";
    display: block;
    width: 1.5px;
    height: 20px;
    background-color: #8cce65;
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:after {
    transform: rotate(-90deg);
  }

  &_open {
    opacity: 1;
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.content {
  // margin: 24px 0;
  height: fit-content;
}

.subPerkDescription {
  margin-bottom: 16px;
  font-size: 14px;
}
