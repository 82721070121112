@import "../../../styles/theme.scss";

@keyframes cardAnimateIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes colorAnimateOut {
  from {
    top: 0;
  }

  to {
    top: calc(-100% * (10 / 9));
  }
}

$animation-multiplier: 1.3;
$base-delay: 0.6s;

$delay-1: $base-delay * $animation-multiplier;
$delay-2: ($base-delay + 0.1) * $animation-multiplier;
$delay-3: ($base-delay + 0.2) * $animation-multiplier;
$delay-4: ($base-delay + 0.3) * $animation-multiplier;

$duration-1: 0.7s * $animation-multiplier;
$duration-2: 0.5s * $animation-multiplier;
$duration-3: 0.5s * $animation-multiplier;
$duration-4: 0.4s * $animation-multiplier;

// $color-delay-1: $base-delay * $animation-multiplier;
// $color-delay-2: ($base-delay + 0.1) * $animation-multiplier;
// $color-delay-3: ($base-delay + 0.2) * $animation-multiplier;
// $color-delay-4: ($base-delay + 0.3) * $animation-multiplier;

.animateInContainer {
  opacity: 0;
  animation-name: cardAnimateIn;
  animation-fill-mode: forwards;
  user-select: none;
  pointer-events: none;

  &_noAnimate {
    animation: none;
    opacity: 1;

    .card:before {
      animation: none;
      top: calc(-100% * (10 / 9));
    }
  }

  &:nth-child(odd) .card {
    flex-direction: column-reverse;
  }

  &:nth-child(1) {
    &,
    .card:before {
      animation-duration: $duration-1;
      animation-delay: $delay-1;
    }

    .card .mainImageContainer {
      aspect-ratio: 310 / 180;
    }
  }
  &:nth-child(2) {
    &,
    .card:before {
      animation-duration: $duration-3;
      animation-delay: $delay-3;
    }

    .card .mainImageContainer {
      aspect-ratio: 338 / 334;
    }
  }
  &:nth-child(3) {
    &,
    .card:before {
      animation-duration: $duration-2;
      animation-delay: $delay-2;
    }

    .card .mainImageContainer {
      aspect-ratio: 408 / 207;
    }
  }
  &:nth-child(4) {
    &,
    .card:before {
      animation-duration: $duration-4;
      animation-delay: $delay-4;
    }
    .card .mainImageContainer {
      aspect-ratio: 264 / 241;
    }
  }

  @media (max-width: 1100px) {
    &:nth-child(odd) .card {
      flex-direction: column;
    }
    &:nth-child(n) .card {
      .mainImageContainer {
        aspect-ratio: 310 / 200;
      }
    }
  }
}
.card {
  background-color: white;
  box-shadow: 0 15px 30px 0 rgba(9, 28, 55, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
  pointer-events: all;
  transform: translate(0);

  cursor: pointer;

  &:hover {
    box-shadow: 0px 20px 40px rgba(9, 28, 55, 0.1);
    transform: translate(0, -8px);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% * (10 / 9));
    background-color: $main;
    background: linear-gradient(
      180deg,
      rgba(140, 206, 101, 1) 0%,
      rgba(140, 206, 101, 1) 90%,
      rgba(140, 206, 101, 0) 100%
    );
    z-index: 2;

    animation-name: colorAnimateOut;
    animation-fill-mode: forwards;
  }

  &__noAnimate {
    &:before {
      display: none;
    }

    &:hover {
      box-shadow: 0 15px 30px 0 rgba(9, 28, 55, 0.1);
      transform: none;
    }
  }
}

.cardText {
  padding: 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.brandLogo {
  max-width: 100px;
  max-height: 40px;
  margin-bottom: 8px;
  pointer-events: none;
  object-fit: contain;
}

.mainImageContainer {
  max-height: 340px;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.availableToClaimContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: $green-highlight;

  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 1.54px;
  padding: 8px 18px 10px;

  border-radius: 50px;
}

.alreadyClaimedContainer {
  position: absolute;
  display: flex;
  top: 20px;
  left: 20px;
  background-color: $navy;
  color: white;

  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 1.54px;
  padding: 8px 18px 10px;

  border-radius: 50px;
}

.perkImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.cardTitle {
  margin-bottom: 24px;
  @media (max-width: 1100px) {
    // height: 53px;
    overflow: hidden;
  }
  @media (max-width: $small) {
    // height: 40px;
  }
}

.button {
  padding-bottom: 0;
  padding-top: 0;
  min-height: 20px;
}

.detailContainer {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.detailTitle {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 2.09px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.detail {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 0;
  color: $navy;
}

.clock {
  margin-right: 8px;
}

.qualifyingBrands {
  margin-top: 16px;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.limitedEdition {
  background-color: $pale-mint;
  border-radius: 16px;
  padding: 4px 18px 6px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  color: $navy;
}

.star {
  height: 12px;
  margin-right: 8px;
}
